import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['tag']

  connect() {
    // console.log("Hello, Stimulus!", this.element)
  }

  select_tag() {
    let filterScenarioFrom = document.getElementById('filter-scenario-form')
    Rails.fire(filterScenarioFrom, 'submit')
  }
}
