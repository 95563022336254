// This file is automatically compiled by Vite, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
console.log('Vite ⚡️ Ruby')
import '~/stylesheets/application.scss'
import '~/globals.js'
import '~/setup.js'
// import '~/app.js'
// Delay bootstrap, to ensure jQuery is already defined globally by then.
import '~/bootstrap.js'
import '~/controllers/index.js'

// Implement scroll detection even using Observer
document.addEventListener("turbo:load", function () {
  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  $(document).ready(function () {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 50) {
        $('#back-to-top').fadeIn();
      } else {
        $('#back-to-top').fadeOut();
      }
    });
    // scroll body to 0px on click
    $('#back-to-top').click(function () {
      $('body,html').animate({
        scrollTop: 0
      }, 400);
      return false;
    });
  });
})
